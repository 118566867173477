import React, { useState } from "react";
import { DefaultUser } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../common/Breadcrumb";
import {
  DefaultButton,
  DefaultPrimaryButton,
  InputError,
  InputWithLabel,
  SelectBox,
} from "../../components/micro";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ConfirmationModel from "../../common/ConfirmationModel";
import { useLocation, useNavigate } from "react-router-dom";

const countryArray = [
  { id: 1, value: "91", label: "91" },
  { id: 2, value: "92", label: "92" },
];
const CreateEditCustomer = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [customerData, setIscustomerData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [resetForm, setResetForm] = useState("");
  const location = useLocation();
  const isEditMode = location?.state;
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    setShowConfirmationModal(true);
  };

  const handleConfirmEdit = (values) => {
    console.log("Form submitted:", values);
    setShowConfirmationModal(false);
  };
  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <DefaultUser />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Customers
              </h6>
            </div>
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbParent2={"Customers"}
            breadCrumbActive={"Create Customers"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <div className="w-full bg-white  shadow drop-shadow-md rounded-lg py-5 px-6">
          <div className="py-4">
            <p className="text-xl font-normal">Create New Customer</p>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              fullName: customerData?.id ? customerData?.full_name : "",
              email: customerData?.id ? customerData?.email : "",
              company: customerData?.id ? customerData?.company : "",
              country: "",
              countryId: customerData?.id ? customerData?.country_id : "",
              address: customerData?.id ? customerData?.address : "",
              postalcode: customerData?.id ? customerData?.postalcode : "",
              office_number: customerData?.id
                ? customerData?.office_number
                : "",
              mobile_number: customerData?.id
                ? customerData?.mobile_number
                : "",
            }}
            validationSchema={Yup.object().shape({
              fullName: Yup.string().min(3).required("Please Enter Name"),
              email: Yup.string().min(3).required("Please Enter E-mail"),
              company: Yup.string().min(2).required("Please Enter Company"),
              office_number: Yup.string()
                .required("please Enter Mobile")
                .max(10, "Mobile is too long - should be 10 chars maximum."),
              mobile_number: Yup.string()
                .required("please Enter Mobile")
                .max(10, "Mobile is too long - should be 10 chars maximum."),
              country: Yup.object().required("Please Select Country"),
              address: Yup.string().min(3).required("Please Enter Address"),
              postalcode: Yup.string()
                .required("Please Enter Postal Code")
                .length(5, "Postal Code must be exactly 5 characters")
                .matches(/^\d+$/, "Postal Code must be numeric"),
            })}
            onSubmit={(values, { resetForm }) => {
              //   setFormValues(values);
              setShowConfirmationModal(true);
              setResetForm(() => resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <div className="grid grid-cols-2 gap-6 mb-4">
                    {/* Name */}
                    <InputWithLabel
                      className={`${
                        isEditMode === "edit" && !isEditing
                          ? "text-textGrey border border-dashed "
                          : "text-black border hover:border-themeBtn"
                      }  ${
                        errors.fullName && touched.fullName
                          ? `border border-red`
                          : `border border-borderTheme/80 focus-within:border-themeBtn `
                      }  transition duration-300 ease-in-out`}
                      idFromName="fullName"
                      label="Name"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setIsDisable(false);
                      }}
                      onBlur={handleBlur}
                      value={values.fullName}
                      errors={errors.fullName && touched.fullName}
                      errorsText={errors.fullName}
                      disabled={isEditMode == "edit" && !isEditing}
                    />
                    {/* company */}
                    <InputWithLabel
                      className={`${
                        isEditMode === "edit" && !isEditing
                          ? "text-textGrey border border-dashed "
                          : "text-black border hover:border-themeBtn"
                      }  ${
                        errors.company && touched.company
                          ? `border border-red`
                          : `border border-borderTheme/80 focus-within:border-themeBtn `
                      }  transition duration-300 ease-in-out`}
                      idFromName="company"
                      label="Company"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        setIsDisable(false);
                      }}
                      onBlur={handleBlur}
                      value={values.company}
                      errors={errors.company && touched.company}
                      errorsText={errors.company}
                      disabled={isEditMode == "edit" && !isEditing}
                    />
                    <div className="gap-3">
                      <div className="grid grid-cols-3 gap-2">
                        {/* Country */}
                        <div>
                          <div
                            className={`${
                              isEditMode === "edit" && !isEditing
                                ? "text-textGrey border border-dashed "
                                : "text-black hover:border-themeBtn"
                            }  ${
                              errors.country && touched.country
                                ? ` border-red border`
                                : ` border-borderTheme/80`
                            } relative bg-white transition duration-300 rounded plus-number-inputs`}
                          >
                            <SelectBox
                              id="country"
                              placeholder="Country"
                              isLoading={isLoading}
                              options={countryArray}
                              getOptionValue={(option) => option.value}
                              getOptionLabel={(option) => option.label}
                              disabled={isEditMode == "edit" && !isEditing}
                              value={
                                countryArray?.length > 0 &&
                                countryArray?.find((op) => {
                                  return op.id === values?.country?.id;
                                })
                              }
                              onChange={(e) => {
                                setFieldValue("country", e);
                                setFieldValue("countryId", e?.id);
                              }}
                            />
                            <label
                              htmlFor="country"
                              className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            >
                              Country
                            </label>
                          </div>
                          {errors.country && touched.country ? (
                            <InputError errorTitle={errors.country} />
                          ) : null}
                        </div>
                        {/* Phone */}
                        <div className="col-span-2">
                          <InputWithLabel
                            className={`${
                              isEditMode === "edit" && !isEditing
                                ? "text-textGrey border border-dashed "
                                : "text-black border hover:border-themeBtn"
                            }  ${
                              errors.office_number && touched.office_number
                                ? `border border-red`
                                : `border border-borderTheme/80 focus-within:border-themeBtn `
                            }  transition duration-300 ease-in-out`}
                            idFromName="office_number"
                            label="Mobile"
                            type={"number"}
                            onChange={(e) => {
                              handleChange(e);
                              setIsDisable(false);
                            }}
                            onBlur={handleBlur}
                            value={values.office_number}
                            errors={
                              errors.office_number && touched.office_number
                            }
                            errorsText={errors.office_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                      {/* Country */}
                      <div>
                        <div
                          className={`${
                            isEditMode === "edit" && !isEditing
                              ? "text-textGrey border border-dashed "
                              : "text-black hover:border-themeBtn"
                          }  ${
                            errors.country && touched.country
                              ? ` border-red border`
                              : ` border-borderTheme/80`
                          } relative bg-white transition duration-300 rounded plus-number-inputs`}
                        >
                          <SelectBox
                            id="country"
                            placeholder="Country"
                            isLoading={isLoading}
                            options={countryArray}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                            disabled={isEditMode == "edit" && !isEditing}
                            value={
                              countryArray?.length > 0 &&
                              countryArray?.find((op) => {
                                return op.id === values?.country?.id;
                              })
                            }
                            onChange={(e) => {
                              setFieldValue("country", e);
                              setFieldValue("countryId", e?.id);
                            }}
                          />
                          <label
                            htmlFor="country"
                            className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                          >
                            Country
                          </label>
                        </div>
                        {errors.country && touched.country ? (
                          <InputError errorTitle={errors.country} />
                        ) : null}
                      </div>
                      {/* Phone */}
                      <div className="col-span-2">
                        <InputWithLabel
                          className={`${
                            isEditMode === "edit" && !isEditing
                              ? "text-textGrey border border-dashed "
                              : "text-black border hover:border-themeBtn"
                          }  ${
                            errors.mobile_number && touched.mobile_number
                              ? `border border-red`
                              : `border border-borderTheme/80 focus-within:border-themeBtn `
                          }  transition duration-300 ease-in-out`}
                          idFromName="mobile_number"
                          label="Mobile"
                          type={"number"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile_number}
                          errors={errors.mobile_number && touched.mobile_number}
                          errorsText={errors.mobile_number}
                          disabled={isEditMode == "edit" && !isEditing}
                        />
                      </div>
                    </div>
                    <div>
                      {/* Email */}
                      <InputWithLabel
                        className={`${
                          isEditMode === "edit" && !isEditing
                            ? "text-textGrey border border-dashed "
                            : "text-black border hover:border-themeBtn"
                        }  ${
                          errors.email && touched.email
                            ? `border border-red`
                            : `border border-borderTheme/80 focus-within:border-themeBtn `
                        }  transition duration-300 ease-in-out`}
                        idFromName="email"
                        label="Email"
                        type="email"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        errors={errors.email && touched.email}
                        errorsText={errors.email}
                        disabled={isEditMode == "edit" && !isEditing}
                      />
                    </div>
                    <div></div>
                    <div>
                      {/* Adddress */}
                      <InputWithLabel
                        className={`${
                          isEditMode === "edit" && !isEditing
                            ? "text-textGrey border border-dashed "
                            : "text-black border hover:border-themeBtn"
                        }  ${
                          errors.address && touched.address
                            ? `border border-red`
                            : `border border-borderTheme/80 focus-within:border-themeBtn `
                        }  transition duration-300 ease-in-out`}
                        idFromName="address"
                        label="Address*"
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.address}
                        errors={errors.address && touched.address}
                        errorsText={errors.address}
                        disabled={isEditMode == "edit" && !isEditing}
                      />
                    </div>
                    <div>
                      {/* postal code */}
                      <InputWithLabel
                        className={`${
                          isEditMode === "edit" && !isEditing
                            ? "text-textGrey border border-dashed "
                            : "text-black border hover:border-themeBtn"
                        }  ${
                          errors.postalcode && touched.postalcode
                            ? `border border-red`
                            : `border border-borderTheme/80 focus-within:border-themeBtn `
                        }  transition duration-300 ease-in-out`}
                        idFromName="postalcode"
                        label="Postal Code*"
                        type="text"
                        onChange={(e) => {
                          handleChange(e);
                          setIsDisable(false);
                        }}
                        onBlur={handleBlur}
                        value={values.postalcode}
                        errors={errors.postalcode && touched.postalcode}
                        errorsText={errors.postalcode}
                        disabled={isEditMode == "edit" && !isEditing}
                      />
                    </div>
                  </div>
                </div>
                {/* Submit button */}
                <div className="flex justify-end mt-3 gap-2">
                  <DefaultPrimaryButton
                    value="Cancel"
                    onClick={() => navigate("/customer")}
                  />
                  <DefaultButton
                    type="submit"
                    value="Create"
                    disabled={isDisable}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModel
          isLoading={false}
          confirmText={isEditMode ? "Edit" : "Create"}
          onClose={(e) => {
            setShowConfirmationModal(false);
          }}
          onConfirm={() => {
            handleSubmit();
            setShowConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default CreateEditCustomer;
