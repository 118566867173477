import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  Calender,
  DollarVactor,
  Paper,
  SavedVector,
} from "../../assets/svg/AllSvg";
import QuatationTable from "./QuatationTable";

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <>
      <div>
        <div className="bg-secondary_brown p-4 rounded-xl flex justify-between items-center">
          <p className="text-white text-xl font-normal">Dashboard</p>
          <div className="max-w-sm">
            <ReactDatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy"
              selectsStart
              id="date_time"
              placeholderText={"Date Range"}
              className="rounded-lg w-36 text-center border-0 py-2 focus:outline-none"
            />
          </div>
        </div>

        <div className="mt-5 grid grid-cols-2 gap-4">
          <div className="bg-light_green px-5 py-8 rounded-lg">
            <div className="flex gap-4">
              <div className="pt-1">
                <DollarVactor />
              </div>
              <div>
                <p className="text-2xl text-light_grey font-normal">Revenue</p>
                <p className="text-5xl font-normal text-green">$1,588.00</p>
              </div>
            </div>
          </div>
          <div className="bg-light_orange px-5 py-8 rounded-lg">
            <div className="flex gap-4">
              <div className="pt-1">
                <Calender />
              </div>
              <div>
                <p className="text-2xl text-light_grey font-normal">
                  Total Booking
                </p>
                <p className="text-5xl font-normal text-light_grey">40</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 bg-primary_red  px-5 py-8 rounded-xl">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Paper />
              <p className="text-xl font-normal">Invoice</p>
            </div>
            <div>
              <p className="text-sm font-normal cursor-pointer text-disable_grey">
                See all
              </p>
            </div>
          </div>
          <div className="grid grid-cols-3 mt-6">
            <div>
              <p className="text-disable_grey text-sm font-normal">
                Issued (10)
              </p>
              <p className="text-light_grey text-3xl font-normal">
                $10,721.53{" "}
              </p>
            </div>
            <div>
              <p className="text-disable_grey text-sm font-normal">
                Pending Payment (9)
              </p>
              <p className="text-primary_orange text-3xl font-normal">
                $10,721.53{" "}
              </p>
            </div>
            <div>
              <p className="text-disable_grey text-sm font-normal">Paid (1)</p>
              <p className="text-green text-3xl font-normal">$10,721.53 </p>
            </div>
          </div>
        </div>

        <div className="flex items-start p-4 space-y-4 md:space-y-0 md:space-x-4">
          <div className="bg-white rounded-lg shadow-md p-6 w-full ">
            <div className="flex justify-between mb-4">
              <p className="text-2xl font-normal">
                Quotation for Confirmation (6)
              </p>
              <p className="text-sm font-normal text-disable_grey cursor-pointer">
                See All
              </p>
            </div>
            <QuatationTable />
          </div>
          <div className=" bg-primary_red  px-5 py-8 rounded-xl w-full">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <SavedVector />
                <p className="text-xl font-normal">Quotation</p>
              </div>
              <div>
                <p className="text-sm font-normal cursor-pointer text-disable_grey">
                  See all
                </p>
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <div>
                <p className="text-disable_grey text-sm font-normal">
                  Issued (10)
                </p>
                <p className="text-light_grey text-3xl font-normal">
                  $10,721.53{" "}
                </p>
              </div>
              <div>
                <p className="text-disable_grey text-sm font-normal">
                  Pending Payment (9)
                </p>
                <p className="text-primary_orange text-3xl font-normal">
                  $10,721.53{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
