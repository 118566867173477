import React, { useState } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import { DefaultUser, SortDownArrow } from "../../assets/svg/AllSvg";
import { AddButton, InputWithLabel, SelectBox } from "../../components/micro";
import DebounceSearch from "../../common/DebounceSearch";
import Action from "../../common/Action";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/customtable/CustomTable";

const customerdata = [
  {
    no: "1",
    name: "abc",
    company: "Company 1",
    email: "name1@email.com",
    phone: "+65 9923 0049",
  },
  {
    no: "2",
    name: "abc",
    company: "Company 1",
    email: "name1@email.com",
    phone: "+65 9923 0049",
  },
  {
    no: "3",
    name: "abc",
    company: "Company 1",
    email: "name1@email.com",
    phone: "+65 9923 0049",
  },
  {
    no: "4",
    name: "abc",
    company: "Company 1",
    email: "name1@email.com",
    phone: "+65 9923 0049",
  },
];
const Customer = () => {
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const company = [
    {
      label: "us",
      value: "us",
    },
    {
      label: "uk",
      value: "uk",
    },
  ];

  //   table header
  const ColumnHeaders = () => (
    <>
      <th className="p-2 font-Proxima text-sm font-medium py-4 px-4 text-start">
        No.
      </th>
      <th className="p-2 font-Proxima text-sm font-medium py-4 px-4 text-start">
        <div
          className="flex items-center cursor-pointer gap-1"
          //   onClick={() => {
          //     setSortBy("equipment");
          //     setSortDirection(sortDirection === "desc" ? "asc" : "desc");
          //   }}
        >
          <span>Name</span>
          <SortDownArrow
          // className={`ml-1 transition-transform
          // ${
          //   sortDirection === "asc" && sortBy === "equipment"
          //     ? "rotate-180"
          //     : ""}`
          //     }
          />
        </div>
      </th>
      <th className="p-2 font-Proxima text-sm font-medium py-4 px-4 text-start">
        <div
          className="flex items-center cursor-pointer gap-1"
          //   onClick={() => {
          //     setSortBy("equipment");
          //     setSortDirection(sortDirection === "desc" ? "asc" : "desc");
          //   }}
        >
          <span>Company</span>
          <SortDownArrow
          // className={`ml-1 transition-transform
          // ${
          //   sortDirection === "asc" && sortBy === "equipment"
          //     ? "rotate-180"
          //     : ""}`
          //     }
          />
        </div>
      </th>
      <th className="p-2 font-Proxima text-sm font-medium py-4 px-4 text-start">
        Email
      </th>
      <th className="p-2 font-Proxima text-sm font-medium py-4 px-4 text-start">
        Mobile
      </th>
      <th className="p-2 font-Proxima text-sm font-medium py-4 px-4 text-start">
        Actions
      </th>
    </>
  );
  // Table Rows
  const DataRows = () => (
    <>
      {(customerdata || []).map((item, index) => (
        <tr
          key={item.id}
          className="font-Proxima shadow-sm hover:bg-shadedgrey border-b"
        >
          <td className="px-5 py-3 truncate">{index + 1}</td>
          <td className="px-5 py-3 truncate">{item.name}</td>
          <td className="px-5 py-3 truncate">{item.company}</td>
          <td className="px-5 py-3 truncate">{item.email}</td>
          <td className="px-5 py-3 truncate">{item.phone}</td>
          <td className="flex px-5 py-3">
            <Action
              optionList={
                <>
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                    onClick={() =>
                      navigate("/view-customer", {
                        state: { customer: item, mode: "edit" },
                      })
                    }
                  >
                    View/Edit
                  </li>
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-light_primaryGrey transition duration-300 cursor-pointer"
                    // onClick={() => {
                    //   setConfirmDelete(true);
                    //   setEquipmentDelete(item?.id);
                    // }}
                  >
                    Delete
                  </li>
                </>
              }
            />
          </td>
        </tr>
      ))}
    </>
  );
  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <DefaultUser />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Customers
              </h6>
            </div>
            <AddButton
              value="Add Customer"
              onClick={() =>
                navigate("/create-edit-customer", {
                  state: { mode: "create" },
                })
              }
            />
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Customers"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <div className="flex gap-2 bg-white border rounded-md border-secondaryWhite p-4">
          <div className="w-52">
            <SelectBox
              className={"w-full max-w-52 p-0"}
              id="Company"
              placeholder="company"
              options={company}
              getOptionValue={(selectedRole) => selectedRole.value}
              getOptionLabel={(selectedRole) => selectedRole?.label}
              onChange
              paddingY={"3px"}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #0000003B`,
                  borderRadius: "0.3rem",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #0000003B",
                  },
                }),
              }}
            />
          </div>
          <div className="w-3/4">
            <DebounceSearch
              className="max-w-3/4"
              search={searchValue}
              handleChange={setSearchValue}
              placeholder={"Search by title"}
            />
          </div>
        </div>
        {/* customtable */}
        <div className="w-full bg-white shadow rounded-lg">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={customerdata}
            showPagination
            overflowVisibleLg
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            total={customerdata?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>
    </>
  );
};

export default Customer;
