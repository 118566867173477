import React from "react";
import CustomTable from "../../components/customtable/CustomTable";

const customerdata = [
  {
    name: "abc",
    company: "Company 1",
    amount: "12313",
  },
  {
    name: "abc",
    company: "Company 1",
    amount: "12313",
  },
  {
    name: "abc",
    company: "Company 1",
    amount: "12313",
  },
  {
    name: "abc",
    company: "Company 1",
    amount: "12313",
  },
  {
    name: "abc",
    company: "Company 1",
    amount: "12313",
  },
  {
    name: "abc",
    company: "Company 1",
    amount: "12313",
  },
];
const QuatationTable = () => {
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b table_responsive_text font-semibold text-left bg-secondary_light_shades whitespace-nowrap "
        >
          Client name
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-secondary_light_shades"
        >
          Company
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-secondary_light_shades"
        >
          Amount
        </th>
      </>
    );
  };
  const DataRows = () => (
    <>
      {(customerdata || []).map((item, index) => (
        <tr key={item.id} className="font-Proxima shadow-sm ">
          <td className="px-5 py-3 truncate">{item.name}</td>
          <td className="px-5 py-3 truncate">{item.company}</td>
          <td className="px-5 py-3 truncate">{item.amount}</td>
        </tr>
      ))}
    </>
  );
  return (
    <div>
      <div>
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={customerdata}
          loading={false}
          showPagination={false}
        />
      </div>
    </div>
  );
};

export default QuatationTable;
