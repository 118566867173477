import React from "react";
import { DefaultUser } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";

const ViewCustomer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location?.state;
  console.log(isEditMode, "isEditMode");
  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <DefaultUser />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Customers
              </h6>
            </div>
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbParent2={"Customers"}
            breadCrumbActive={"Create Customers"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <div className="bg-primary_red p-6 rounded-lg">
          <div className="flex justify-between items-center border-b border-b-secondary_grey pb-5 mb-4">
            <div className="flex items-center gap-2">
              <DefaultUser />
              <p className="text-xl font-normal text-primary_black">
                Customer Details
              </p>
            </div>
            <div>
              <p
                className="text-secondary_brown border-b border-b-secondary_brown cursor-pointer"
                onClick={() => navigate("/create-edit-customer")}
              >
                Edit
              </p>
            </div>
          </div>
          <div className="space-y-4">
            <div>
              <p className="text-sm font-normal text-primary_black">
                Customer Name:
              </p>
              <p className="text-base font-bold text-primary_black">
                Customer Name 1
              </p>
            </div>
            <div>
              <p className="text-sm font-normal text-primary_black">
                Company Name:
              </p>
              <p className="text-base font-bold text-primary_black">
                Company 123
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Office Number:
                </p>
                <p className="text-base font-bold text-primary_black">
                  +65 6988 5566
                </p>
              </div>
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Mobile Number:
                </p>
                <p className="text-base font-bold text-primary_black">
                  +65 9988 5566
                </p>
              </div>
            </div>
            <div>
              <p className="text-sm font-normal text-primary_black">Email:</p>
              <p className="text-base font-bold text-primary_black">
                company123@email.com
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Address:
                </p>
                <p className="text-base font-bold text-primary_black">
                  Blk A, Habour Front Center #23-345
                </p>
              </div>
              <div>
                <p className="text-sm font-normal text-primary_black">
                  Postal Code:
                </p>
                <p className="text-base font-bold text-primary_black">123456</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCustomer;
