import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";
import PasswordSuccessful from "../pages/auth/PasswordSuccessful";
import Auth from "../pages/auth/Auth";
import DefaultLayout from "../layouts";
import Dashboard from "../pages/dashboard/Dashboard";
import Customer from "../pages/customer/Customer";
import SalesQuatation from "../pages/sales/SalesQuatation";
import Page404 from "../common/Page404";
import Page500 from "../common/Page500";
import CreateEditCustomer from "../pages/customer/CreateEditCustomer";
import ViewCustomer from "../pages/customer/ViewCustomer";
import Package from "../pages/package/Package";
const AppRoutes = () => {
  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" />
      <Routes>
        {/* Auth */}
        <Route exact path="/login" element={<Auth />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/success-password" element={<PasswordSuccessful />} />

        {/* DefaultLayout */}
        <Route path="/" element={<DefaultLayout />}>
          {/* Dashboard */}
          <Route index exact element={<Dashboard />} />
          {/* customer */}
          <Route path="/customer" element={<Customer />} />
          <Route
            path="/create-edit-customer"
            element={<CreateEditCustomer />}
          />
          <Route path="/view-customer" element={<ViewCustomer />} />
          {/* package */}
          <Route path="/package" element={<Package />} />
          {/* 404 page */}
          <Route
            exact
            path="*"
            name="Page 404"
            status={404}
            element={<Page404 />}
          />
          {/* Page 500 */}
          <Route
            exact
            path="/500"
            name="Page 500"
            status={500}
            element={<Page500 />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
