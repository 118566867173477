import React from "react";
import { SettingVector } from "../../assets/svg/AllSvg";
import { AddButton } from "../../components/micro";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";

const customerdata = [
  {
    no: "1",
    package_name: "abc",
    price: "102",

    email: "package_name1@email.com",
    phone: "+65 9923 0049",
  },
  {
    no: "2",
    package_name: "abc",
    company: "Company 1",
    email: "package_name1@email.com",
    phone: "+65 9923 0049",
  },
  {
    no: "3",
    package_name: "abc",
    company: "Company 1",
    email: "package_name1@email.com",
    phone: "+65 9923 0049",
  },
  {
    no: "4",
    package_name: "abc",
    company: "Company 1",
    email: "package_name1@email.com",
    phone: "+65 9923 0049",
  },
];
const Package = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="space-y-5">
        <div className="mb-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <SettingVector />
              <h6 className="font-opensans font-normal sm:text-xl text-lg ">
                Packages Setting
              </h6>
            </div>
            <AddButton
              value="Add Customer"
              onClick={() =>
                navigate("/create-edit-customer", {
                  state: { mode: "create" },
                })
              }
            />
          </div>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Packages Setting"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
      </div>
    </>
  );
};

export default Package;
