import React from "react";
import { Logo } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { SucceessVactor } from "../../assets/svg/AllSvg";

const PasswordSuccessful = () => {
  const navigate = useNavigate();

  return (
    <div className="main flex justify-center items-center h-screen">
      <section className="w-full">
        <div className="max-w-sm w-full mx-auto text-center">
          {/* Success Icon */}
          <div className="flex items-center justify-center mb-6">
            <SucceessVactor />
          </div>

          {/* Heading and Message */}
          <div className="mb-8">
            <h1 className="sm:text-3xl text-lg font-opensans font-normal mb-1">
              Reset password
            </h1>
            <p className="text-5xl font-light text-primary_black">
              Successful!
            </p>
          </div>
          <button
            type="submit"
            className="w-full block text-white bg-maintheme text-center text-base border-2 border-transparent uppercase rounded transition duration-500 py-2 lg:px-7 px-4"
            onClick={() => navigate("/login")}
          >
            login now
          </button>
        </div>
      </section>
    </div>
  );
};

export default PasswordSuccessful;
