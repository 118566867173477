import ReactSelect from "react-select";
import { Plus } from "../../assets/svg/AllSvg";
import { useEffect, useState } from "react";

export const Input = ({ className, ...res }) => {
  return <input className={className} {...res} />;
};
export const InputError = ({ errorTitle, classes }) => {
  return (
    <>
      <div
        className={`flex items-center text-maintheme sm:text-sm text-base mt-2 ${classes}`}
      >
        <p className="ml-1">{errorTitle}</p>
      </div>
    </>
  );
};
export const InputWithLabel = ({
  className,
  idFromName,
  label,
  type,
  errors,
  inputClass,
  errorsText,
  icon,
  ...res
}) => {
  return (
    <div>
      <div
        className={`${className} relative bg-white transition duration-300 rounded`}
      >
        <Input
          type={type}
          id={idFromName}
          name={idFromName}
          className={`block px-2.5  py-3 w-full  text-base error-forms bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${inputClass}`}
          placeholder={" "}
          {...res}
        />
        <label
          htmlFor={idFromName}
          className="absolute text-light_grey pointer-events-none  text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-5 left-1"
        >
          {label}
        </label>
        {icon && <>{icon}</>}
      </div>
      {errors ? <InputError errorTitle={errorsText} /> : null}
    </div>
  );
};
export const CheckBox = ({
  className,
  idFrom,
  name,
  label,
  labelClass,
  ...res
}) => {
  return (
    <label
      htmlFor={idFrom}
      className={`flex items-center space-x-2 ${className}`}
    >
      <input
        type="checkbox"
        name={name}
        id={idFrom}
        className="w-0 h-0 peer"
        {...res}
      />
      <span
        className={`sm:max-w-5 max-w-4 sm:min-w-5 min-w-4 w-full sm:h-5 h-4 border-2 border-secondary_grey inline-block rounded peer-checked:border-theme relative peer-checked:after:absolute peer-checked:after:top-1/2 peer-checked:after:left-1/2  peer-checked:after:w-full peer-checked:after:h-[calc(100%+3px)] peer-checked:after:bg-check_img peer-checked:after:-translate-x-1/2 peer-checked:after:-translate-y-1/2`}
      ></span>
      <p className={`responsive_base_text font-sfPro ${labelClass}`}>{label}</p>
    </label>
  );
};
export const SelectBox = ({
  options,
  placeholder,
  errors,
  errorsText,
  className,
  paddingY,
  paddingX,
  ...res
}) => {
  return (
    <div>
      <ReactSelect
        className={`text-black p-0 responsive_base_text ${className}`}
        placeholder={placeholder}
        options={options}
        {...res}
        styles={{
          control: (base) => ({
            ...base,
            border: `1px solid ${errors ? "red" : "#0000003B"}`,
            borderRadius: "0.25rem",
            padding: `${paddingY ?? "6px"} ${paddingX ?? "4px"}`,
            boxShadow: "none",
            "&:hover": {
              border: "1px solid #0000003B",
            },
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? "#C71212" : "white",
            "&:active": {
              backgroundColor: state.isSelected
                ? "#C71212"
                : "rgba(0, 0, 0, 0.04)",
            },
          }),
        }}
      />
      {errors ? <InputError errorTitle={errorsText} /> : null}
    </div>
  );
};
export const ButtonTag = ({ className, value, type, ...rest }) => {
  return (
    <>
      <button
        // type={"type"}
        className={`font-semibold xl:text-base text-base transition duration-500 w-full py-2 lg:px-4 px-2 rounded-md bg-maintheme/20 ${className}`}
        {...rest}
      >
        {value}
      </button>
    </>
  );
};
export const AddButton = ({ className, value, ...res }) => {
  return (
    <div className={`flex justify-center  ${className}`}>
      <button
        className="bg-maintheme text-white flex items-center justify-center gap-2 px-4 py-2.5 rounded uppercase font-Proxima "
        type="button"
        {...res}
      >
        <Plus className="fill-white mr-2" />
        <p>{value}</p>
      </button>
    </div>
  );
};
export const useDebounce = (value, delay = 500) => {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const handleSearch = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => {
      clearTimeout(handleSearch);
    };
  }, [delay, value]);
  return debounceValue;
};
export const DefaultButton = ({ className, type, disabled, value, ...res }) => {
  return (
    <div className={`flex justify-center py-2 ${className}`}>
      <button
        className={`${
          disabled
            ? "bg-light_secondary_Grey cursor-not-allowed text-dark_secondary_Grey"
            : "bg-maintheme"
        } text-white flex items-center justify-center px-6 py-2.5 rounded uppercase`}
        type={type ?? "button"}
        disabled={!!disabled}
        {...res}
      >
        <p className="font-Proxima text-[15px]">{value}</p>
      </button>
    </div>
  );
};
export const DefaultPrimaryButton = ({ className, type, value, ...res }) => {
  return (
    <div className={`flex justify-center py-2 ${className}`}>
      <button
        className=" text-primary_black border border-primary_black flex items-center justify-center px-6 py-2.5 rounded uppercase "
        type={type ?? "button"}
        {...res}
      >
        <p className="font-Proxima text-[15px]">{value}</p>
      </button>
    </div>
  );
};
export const Badge = ({ className, value, ...res }) => {
  return (
    <p
      className={`border rounded-full px-3 py-2 text-[13px] font-normal text-center font-Proxima  ${className}`}
      {...res}
    >
      {value}
    </p>
  );
};
